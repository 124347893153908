import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Button from '../button'
import './testimonial.scss'
import { ReactComponent as Quote } from "./MIM_Icon_Quote.svg"
import { ReactComponent as QuoteGradient } from "./MIM_Icon_QuoteGradient.svg"


/*
 * This component is built using `gatsby-image` to automatically serve optimized
 * images with lazy loading and reduced file sizes. The image is loaded using a
 * `useStaticQuery`, which allows us to load the image from directly within this
 * component, rather than having to pass the image data down from pages.
 *
 * For more information, see the docs:
 * - `gatsby-image`: https://gatsby.dev/gatsby-image
 * - `useStaticQuery`: https://www.gatsbyjs.org/docs/use-static-query/
 */

const Image = () => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(
        relativePath: { eq: "20-MIM-0008_Homepage_CA-8.png" }
      ) {
        childImageSharp {
          fluid(maxWidth: 2000) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <div
      style={{
        position: "relative",
        zIndex: 0,
        backgroundColor: "white",
      }}
    >
      <Img fluid={data.placeholderImage.childImageSharp.fluid} />
      <div className="testimonial-copy">
        <span>
          <Quote></Quote>
          <QuoteGradient></QuoteGradient>
        </span>
        <p>
          {/* You guys do an awesome job, couldn’t be happier! Dustin Hardy, Maple Row
          <br/><br/> */}
          I tell people you’re not the cheapest, but you are the best! Equipment
          is always nice and updated, you guys are available when I need you, No
          complaints whatsoever!
          <br />
          <br />
          {/* What a roadshow! Bas DeJong, Hickory Gables
          <br/><br/> */}
          <span className="author">Neal Travis, NVF Inc</span>
        </p>
        <p>
          <Button
            style={{
              backgroundColor: `rgb(151, 193, 61)`,
              color: "white",
              border: `none`,
            }}
            to="/testimonials"
            label="SEE WHAT PEOPLE ARE SAYING!"
          ></Button>
        </p>
      </div>
    </div>
  )
}

export default Image
