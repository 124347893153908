import React from "react"
import "./carousel.scss"
import Button from '../button'
import Carousel from "react-bootstrap/Carousel"
import Image1 from "../CAImages/image1"
import Image2 from "../CAImages/image2"
import Image3 from "../CAImages/image3"
import pages from '../../data/pages'
function HomeCarousel(props) {
  return (
    <Carousel className="wrapper">
      <Carousel.Item>
        <Image1></Image1>
        <Carousel.Caption>
          <h3>GROWING ABOVE AND BEYOND. SINCE 2012.</h3>
          <p>
            <Button to={pages[2].path} label="READ OUR STORY!"></Button>
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Image2></Image2>
        <Carousel.Caption>
          <h3>SERVING GROWERS WITH THE BEST TECH.</h3>
          <p>
            <Button to={pages[0].path} label="SEE HOW WE CAN HELP!"></Button>
          </p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <Image3></Image3>
        <Carousel.Caption>
          <h3>IN IT FOR THE LONG HAUL.</h3>
          <p>
            <Button to={pages[1].path} label="PARTNER WITH US TODAY!"></Button>
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  )
}

export default HomeCarousel