import React from "react"
import Layout from "../components/layout"
import Carousel from "../components/carousel"
import SEO from "../components/seo"
import Testimonial from '../components/testimonial'
import CallOuts from '../components/callouts'
// import Gallery from "react-grid-gallery"

import { ReactComponent as Phrase } from "../svg/MIM_Positioning.svg"

import './index.scss'
const IndexPage = () => (
  <Layout className="home-page">
    <SEO title="Home" />
    <div className="carousel-wrapper">
      <Carousel />
    </div>
    <section
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: '4vw'
      }}
    >
      <Phrase className="phrase"></Phrase>
    </section>
    <CallOuts ></CallOuts>
    <Testimonial></Testimonial>
  </Layout>
)

export default IndexPage
